import React from 'react'
import PropTypes from 'prop-types'
import css from './style.module.scss'
import cn from 'classnames'

export default Radio

Radio.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.string,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.string
}

function Radio({
  id,
  name,
  text,
  value,
  defaultChecked = false,
  onChange,
  error,
  tabIndex = undefined,
  disabled = false,
}) {
  return (
    <div className={cn(css.radioWrap, error && css.__error)}>
      <input
        type="radio"
        id={id}
        name={name}
        defaultChecked={defaultChecked}
        onChange={onChange}
        value={value}
        disabled={disabled}
        tabIndex={tabIndex}
      />
      <label htmlFor={id} className={css.label}>
        <div className={css.field}/>
        <p className={cn(css.text, 'label ')} dangerouslySetInnerHTML={{__html: text}} />
      </label>

      {error &&
        <p className='validation__error caption red'>{error}</p>
      }
    </div>
  )
}
