export const userFieldNames = {
  LAST_NAME: 'last_name',
  FIRST_NAME: 'name',
  PATRONYMIC: 'patronymic',
  GENDER: 'sex',
  PHONE: 'phone',
  EMAIL: 'email',
  EMAIL: 'email',
  BIRTH_DAY: 'birth',
  SUBSCRIBE: 'is_subscribe',
  // AGREE: 'is_agree',
  PASSWORD: 'password',
  PASSWORD_CONFIRM: 'password_confirm',
  SMS_CODE: 'sms_code',
  CITY: 'user_city',
  ADDRESS: 'user_address',
  // USER_CITY: 'user_city',
  // USER_ADDRESS: 'user_address',
  CONFIRM_PASSWORD: 'confirm_password',
  OLD_PASSWORD: 'old_password',
  NEW_PASSWORD: 'new_password'
}