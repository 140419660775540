import React, { useEffect, useState } from 'react'
import UserSettings from './View'
import { useFormik } from 'formik'
import { userFieldNames } from 'components/user/constants'
import { fetchUserData, openChangePasswordPopup, updateUserData } from 'components/user/actions'
import { connect, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { omit } from 'ramda'
import * as Yup from 'yup'

const mapStateToProps = state => ({
  userData: state.user.data
})

const mapDispatchToProps = dispatch => ({
  fetchUserData: bindActionCreators(fetchUserData, dispatch),
  openChangePasswordPopup: bindActionCreators(openChangePasswordPopup, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSettingsContainer)

function UserSettingsContainer({
  userData,
  fetchUserData,
  openChangePasswordPopup
}) {
  const dispatch = useDispatch()
  const [isReady, setIsReady] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      [userFieldNames.SUBSCRIBE]: userData[userFieldNames.SUBSCRIBE],
      [userFieldNames.PHONE]: userData[userFieldNames.PHONE] || '',
      [userFieldNames.EMAIL]: userData[userFieldNames.EMAIL] || ''
    },

    onSubmit: values => {
      const preparedValues = omit([
        userFieldNames.PHONE
      ], values)
      dispatch(updateUserData(preparedValues)).then(() => {
        console.log('check')
      }).catch(err => {
        console.log(err)
      })
    },

    validationSchema: Yup.object().shape({
      [userFieldNames.EMAIL]: Yup.string().email('Некорректный email')
    })
  }, [userData])

  useEffect(() => {
    fetchUserData().then(() => {
      setIsReady(true)
    })
  }, [/**/fetchUserData])

  function onClickChangePasswordHandler() {
    openChangePasswordPopup()
  }

  if (!isReady) {
    return null
  }

  return <UserSettings
    values={formik.values}
    errors={formik.errors}
    touched={formik.touched}
    handleChange={formik.handleChange}
    handleSubmit={formik.handleSubmit}
    setFieldValue={formik.setFieldValue}
    onClickChangePassword={onClickChangePasswordHandler}
  />
}
