import React from 'react'
import cn from 'classnames'

export default function ({
  text,
  className,
  blue = false,
  disabled = false,
  ghost = false,
  smaller = false,
  onClick,
  type = '',
  tabIndex = undefined,
}) {
  const specificClassName = cn(
    className,
    (blue ? 'button-blue' : 'button'),
    (smaller ? 'label-2' : 'label'),
    ghost && '--ghost',
    smaller && '--smaller'
  )

  return (
    <button
      className={specificClassName}
      disabled={disabled}
      onClick={onClick}
      type={type}
      tabIndex={tabIndex}
    >
      {text}
    </button>
  )
}
