import React from 'react'
import css from './style.module.scss'
import { InputText, Button, Phone } from 'components/ui'
import cn from 'classnames'
import { userFieldNames as fieldNames } from 'components/user/constants'

export default function({
  handleSubmit,
  handleChange,
  setFieldValue,
  values,
  touched,
  errors
}) {
  return (
    <div className={cn(css.passRecovery, 'form-layout')}>
      <form onSubmit={handleSubmit}>

        <p className="body-text">
          Укажите номер телефона, на который зарегистрирован ваш аккаунт.
        </p>

        <div className={cn('form-field', css.password)}>
          <Phone
            placeholder="Номер телефона"
            name={fieldNames.PHONE}
            setFieldValue={setFieldValue}
            error={touched[fieldNames.PHONE] && errors[fieldNames.PHONE]}
            country={'ru'}
          />
        </div>

        <div className="form-button">
          <Button text="Восстановить пароль" type="submit" />
        </div>

      </form>

    </div>
  )
}
