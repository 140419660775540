// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-index-js": () => import("./../../../src/pages/account/index.js" /* webpackChunkName: "component---src-pages-account-index-js" */),
  "component---src-pages-account-orders-js": () => import("./../../../src/pages/account/orders.js" /* webpackChunkName: "component---src-pages-account-orders-js" */),
  "component---src-pages-account-settings-js": () => import("./../../../src/pages/account/settings.js" /* webpackChunkName: "component---src-pages-account-settings-js" */),
  "component---src-pages-blog-history-[id]-js": () => import("./../../../src/pages/blog-history/[id].js" /* webpackChunkName: "component---src-pages-blog-history-[id]-js" */),
  "component---src-pages-blog-history-index-js": () => import("./../../../src/pages/blog-history/index.js" /* webpackChunkName: "component---src-pages-blog-history-index-js" */),
  "component---src-pages-blog-laws-[id]-js": () => import("./../../../src/pages/blog-laws/[id].js" /* webpackChunkName: "component---src-pages-blog-laws-[id]-js" */),
  "component---src-pages-blog-laws-index-js": () => import("./../../../src/pages/blog-laws/index.js" /* webpackChunkName: "component---src-pages-blog-laws-index-js" */),
  "component---src-pages-blog-rabbi-[id]-js": () => import("./../../../src/pages/blog-rabbi/[id].js" /* webpackChunkName: "component---src-pages-blog-rabbi-[id]-js" */),
  "component---src-pages-blog-rabbi-index-js": () => import("./../../../src/pages/blog-rabbi/index.js" /* webpackChunkName: "component---src-pages-blog-rabbi-index-js" */),
  "component---src-pages-blog-stories-[id]-js": () => import("./../../../src/pages/blog-stories/[id].js" /* webpackChunkName: "component---src-pages-blog-stories-[id]-js" */),
  "component---src-pages-blog-stories-index-js": () => import("./../../../src/pages/blog-stories/index.js" /* webpackChunkName: "component---src-pages-blog-stories-index-js" */),
  "component---src-pages-contacts-index-js": () => import("./../../../src/pages/contacts/index.js" /* webpackChunkName: "component---src-pages-contacts-index-js" */),
  "component---src-pages-cookies-policy-index-js": () => import("./../../../src/pages/cookies-policy/index.js" /* webpackChunkName: "component---src-pages-cookies-policy-index-js" */),
  "component---src-pages-donate-index-js": () => import("./../../../src/pages/donate/index.js" /* webpackChunkName: "component---src-pages-donate-index-js" */),
  "component---src-pages-donate-success-js": () => import("./../../../src/pages/donate/success.js" /* webpackChunkName: "component---src-pages-donate-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-konkurs-index-js": () => import("./../../../src/pages/konkurs/index.js" /* webpackChunkName: "component---src-pages-konkurs-index-js" */),
  "component---src-pages-make-order-index-js": () => import("./../../../src/pages/make-order/index.js" /* webpackChunkName: "component---src-pages-make-order-index-js" */),
  "component---src-pages-make-review-index-js": () => import("./../../../src/pages/make-review/index.js" /* webpackChunkName: "component---src-pages-make-review-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-public-offer-index-js": () => import("./../../../src/pages/public-offer/index.js" /* webpackChunkName: "component---src-pages-public-offer-index-js" */),
  "component---src-pages-reviews-index-js": () => import("./../../../src/pages/reviews/index.js" /* webpackChunkName: "component---src-pages-reviews-index-js" */),
  "component---src-pages-terms-of-service-index-js": () => import("./../../../src/pages/terms-of-service/index.js" /* webpackChunkName: "component---src-pages-terms-of-service-index-js" */),
  "component---src-pages-ui-kit-js": () => import("./../../../src/pages/ui-kit.js" /* webpackChunkName: "component---src-pages-ui-kit-js" */)
}

